import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import avatar from "../assets/img/avatars/avatar.jpg"
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const apiUrlFile = 'https://alvineindianhair.com/dev.app.info/esquire/api/storefile';

export default function Post() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image',
  ];

  const editorStyle = {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '20px',
  };

  const formRef = useRef();

  const [selectedImage, setSelectedImage] = useState(null);

  const [fileurl,setFileUrl] = useState(null);

  const [selectedPostId, setSelectedPostId] = useState(null);

  const [editorDescription, setEditorDescription] = useState('');

  const [posts, setPosts] = useState([]);

  const [datapost,setDataPost] = useState({});

  const [datapostfiles,setDataPostFiles] = useState([]);

  const inputposts = useRef([]);

  const [showModal, setShowModal] = useState(false);

  const [showModalFilePost, setShowModalFilePost] = useState(false);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  const [loadingskeletonbuttonfiles, setLoadingSkeletonButtonFiles] = useState(false);

  const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);

  const [errors,setErrors] = useState({});

  const [success,setSucces] = useState(""); 

  const addInputsPost = el => {
    if (el && !inputposts.current.includes(el)) {
      inputposts.current.push(el)
    }
  }

  const handleModalClose = () => {
    setShowModal(false);
    setErrors({});
    setSelectedPostId(null);
    inputposts.current = [];
    setEditorDescription("");
    setSelectedImage(null);
  };

  const handleModalCloseFiles = () => {
    setSelectedPostId(null);
    setShowModalFilePost(false);
  };

  const handleEditoroDescriptionChange = (html) => {
    setEditorDescription(html);
  };

  const handleModalOpen = async (id) => {
    if (id != "") {
      setSelectedPostId(id);
      await axiosClient.get(`/post/${id}`).then(({data}) => {
        let list = data.data;
        setDataPost(list);
        setEditorDescription(list.post_description);
      });
    }
    inputposts.current = [];   
    setShowModal(true);
  }

  const handleModalOpenFiles = async (id) => {
    setLoadingSkeletonButtonFiles(true);
    if (id != "") {
      setSelectedPostId(id);
      await axiosClient.get(`/post/${id}`).then(({data}) => {
        let list = data.data;
        setDataPostFiles(list.files);
      });
    }
    setShowModalFilePost(true);
    setLoadingSkeletonButtonFiles(false);
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
      setFileUrl(URL.createObjectURL(file));
    } else {
      Swal.fire({position: 'Center',icon: 'warning',title: 'Oops!',text: 'Veuillez sélectionner un fichier image valide.',showConfirmButton: true});
    }
  };

  const addUpdatePost = async(event) => {
    event.preventDefault();
    const errors = {};

    if (inputposts.current[0].value.trim() == '') {
      errors.post_title = 'The name field is required.';
    }        

    if (!editorDescription || editorDescription == '<p><br></p>') {
      errors.post_description = 'The description field is required.';
    }   
    
    if (!selectedPostId) {
      if (!fileurl) {
        errors.post_img = 'This image is required.';
      }  
    }

    if (Object.keys(errors).length === 0) {
      setLoadingSubmitButton(true);
      if (!selectedPostId) {
        const postData = new FormData();
        postData.append('title', inputposts.current[0].value.trim());
        postData.append('description', editorDescription);
        postData.append('img', selectedImage);          
        await axiosClient.post('/posts',postData,{headers: {'Content-Type': 'multipart/form-data',},}).then(async ({data})  => {
          let post_id = data.id;
          fileIds.forEach(async (fileId) => {
            console.log("File ID:", fileId[0]);
            const data = { post_id : post_id, file_id : fileId[0] }
            await axiosClient.post('/storefilespost',data);
          });
          setFiles([]);
          setFileIds([]);
          setTotalFiles(0);
          setCompletedFiles(0);          
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Post added success',showConfirmButton: true});
          setSucces("Post added success !!");
          setErrors(errors);
          setTimeout(() => { setSucces('');}, 3000);
          formRef.current.reset();
          setSelectedImage([]);
          setFileUrl(null);
          setEditorDescription("");
          setLoadingSubmitButton(false);
          getPosts();
        }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
            }            
            if (response && response.status === 500) {
                Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
            }
            setLoadingSubmitButton(false);
        });
      }else{
        const postData = new FormData();
        postData.append('_method', 'PUT');
        postData.append('title', inputposts.current[0].value.trim());
        postData.append('description', editorDescription);
        if (selectedImage) {
          postData.append('img', selectedImage);          
        }

        await axiosClient.post(`/post/${selectedPostId}`,postData,{headers: {'Content-Type': 'multipart/form-data',},}).then(async ({data})  => {          
          setSucces("Post updated success !!");
          setErrors(errors);          
          setTimeout(() => { setSucces('');}, 3000);
          setLoadingSubmitButton(false);
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Post updated success',showConfirmButton: true});
          getPosts();
        }).catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
          }            
          if (response && response.status === 500) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
          }
          setLoadingSubmitButton(false);
        });
      }
    } else{
      setErrors(errors);
      setLoadingSubmitButton(false);
    } 
  }

  const addFiles = async(event) => {
    event.preventDefault();
    console.log(fileIds); 
    fileIds.forEach(async (fileId) => {
      const datafile = { post_id : selectedPostId, file_id : fileId[0] }
      await axiosClient.post('/storefilespost',datafile);
    });
    setTotalFiles(0);
    setCompletedFiles(0); 
    setFiles([]);
    setFileIds([]);
    Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Files added success',showConfirmButton: true});
    handleModalCloseFiles();
  }

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/posts').then( ({data})=> {
      setPosts(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  const handleDelete = async(id) => {
    Swal.fire({
      title: 'Deletion', text: 'do you really want to delete this post?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#10518E', cancelButtonColor: '#d33', confirmButtonText: 'confirm', cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('_method', 'DELETE');
        axiosClient.post(`/post/${id}`,formData).then( () => {
          Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'Post deleted successfully',showConfirmButton: true});
          getPosts()
        }).catch(err => {
          const response = err.response;
          if (response.data.message) {
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `${response.data.message}` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
          }
        });
      }
    });
  };

  const handleDeleteFile = async(id) => {
    Swal.fire({
      title: 'Deletion', text: 'do you really want to delete this file?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#10518E', cancelButtonColor: '#d33', confirmButtonText: 'confirm', cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('_method', 'DELETE');
        axiosClient.post(`/post/files/${id}`,formData).then( () => {
          Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'File deleted successfully',showConfirmButton: true});
          handleModalCloseFiles();
        }).catch(err => {
          const response = err.response;
          if (response.data.message) {
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `${response.data.message}` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
          }
        });
      }
    });
  };

  const [files, setFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);
  const [fileIds, setFileIds] = useState([]);

  const handleFileUploading = (file, uniqueIdentifier) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    formData.append("files[]", file);
    formData.append("type", "file/test");

    xhr.upload.addEventListener("progress", (e) => {
      const progress = Math.round((e.loaded / e.total) * 100);
      updateFileProgress(uniqueIdentifier, progress, e.loaded / 1024, e.total / 1024);
    });

    xhr.open("POST", `${apiUrlFile}`, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        // Associer le file_id au fichier correspondant
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.uniqueIdentifier === uniqueIdentifier
              ? { ...file, file_id: response.file_ids, status: "Completed", color: "#00B125" }
              : file
          )
        );
        setFileIds((prevFileIds) => [...prevFileIds, response.file_ids]);
        setCompletedFiles((prevCompletedFiles) => prevCompletedFiles + 1);
      }
    };

    xhr.send(formData);
    return xhr;
  };

  const updateFileProgress = (uniqueIdentifier, progress, loadedKB, totalKB) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.uniqueIdentifier === uniqueIdentifier
          ? { ...file, progress, loadedKB, totalKB }
          : file
      )
    );
  };

  const updateFileStatus = (uniqueIdentifier, status, color) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.uniqueIdentifier === uniqueIdentifier
          ? { ...file, status, color }
          : file
      )
    );
  };

  const handleSelectedFiles = (selectedFiles) => {
    const filesArray = Array.from(selectedFiles);

    if (filesArray.length === 0) return;

    setTotalFiles((prevTotal) => prevTotal + filesArray.length);

    const newFiles = [...files];
    filesArray.forEach((file, index) => {
      const uniqueIdentifier = Date.now() + index;
      newFiles.push({
        file,
        uniqueIdentifier,
        progress: 0,
        loadedKB: 0,
        totalKB: 0,
        status: "Televersement...",
        color: "#000",
        xhr: handleFileUploading(file, uniqueIdentifier),
      });
    });

    setFiles(newFiles);
  };

  const cancelUpload = (uniqueIdentifier) => {
    setFiles((prevFiles) => {
      const fileToCancel = prevFiles.find((file) => file.uniqueIdentifier === uniqueIdentifier);
      if (fileToCancel && fileToCancel.xhr) {
        fileToCancel.xhr.abort(); // Annuler l'upload
      }

      let updatedCompletedFiles = completedFiles;
      if (fileToCancel && fileToCancel.status === "Completed") {
        updatedCompletedFiles = Math.max(0, completedFiles - 1);
        // Mise à jour de fileIds : suppression de l'ID du fichier annulé
        setFileIds((prevFileIds) =>
          prevFileIds.filter((id) => id !== fileToCancel.file_id)
        );
      }

      setCompletedFiles(updatedCompletedFiles);
      setTotalFiles((prevTotal) => Math.max(0, prevTotal - 1));

      return prevFiles.filter((file) => file.uniqueIdentifier !== uniqueIdentifier);
    });
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    handleSelectedFiles(e.dataTransfer.files);
  };

  // console.log(fileIds); 
  // console.log(files); 

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Posts</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <h5 class="card-title mb-0">Latest Posts</h5>
                    <h5 class="card-title mb-0" style={{float:"right"}}>
                        <Link onClick={() => handleModalOpen("")} title="Ajouter" class="btn btn-primary" style={{ borderRadius : "2px" }}><i class="fa fa-plus"></i> </Link>
                    </h5>
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Likes</th>
                        <th class="d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(7,6)}</>:
                        <>
                            {posts && posts.map((post, index) => {                                                                              
                                const descendingIndex = posts.length - index;

                                let postDescription = post.post_description;
                                if (postDescription.length > 15 ) {
                                  postDescription = postDescription.substr(0, 15)+"..";
                                }
                                return (
                                    <>
                                        <tr>
                                            <td>{descendingIndex}</td>
                                            <td class="d-xl-table-cell">
                                                <Link to={post.post_img} target="_blank">
                                                    <img src={post.post_img} style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                                                </Link>
                                            </td>                                              
                                            <td>{post.post_title}</td>
                                            <td title={post.post_description.replace(/<[^>]+>/g, '')} dangerouslySetInnerHTML={{ __html: postDescription }}></td>
                                            <td>{post.post_like}</td>
                                            <td class="d-md-table-cell">
                                                <a onClick={() => handleModalOpen(post.post_id)} className="text-primary">
                                                    Update
                                                </a>
                                                <a onClick={() => handleDelete(post.post_id)} className="text-danger" style={{marginLeft:"20px"}}>
                                                    Delete 
                                                </a>
                                                <a onClick={() => handleModalOpenFiles(post.post_id)} className="text-primary" style={{marginLeft:"10px"}}>
                                                  Files
                                                </a>                                                                                                                           
                                            </td>
                                        </tr>                                
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>
                  <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>
                        {selectedPostId ? "Update Post" : "Add Post"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form ref={formRef} encType="multipart/form-data">
                          {success ? <div class="alert alert-success alert-dismissible fade show" role="alert">
                              { success }
                          </div>: null}  
                          {errors.error ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
                              { errors.error }
                          </div>: null}
                          {errors.error_img ? <div class="alert alert-danger alert-dismissible fade show" role="alert">
                              { errors.error_img }
                          </div>: null}                        
                          <div class="card">
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Title*</h5><br/>
                                  <input type="text" ref={addInputsPost} class="form-control" defaultValue={selectedPostId ? datapost.post_title : ""}/>
                                  {errors.post_title ? <><span className="text-danger">{errors.post_title}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Description*</h5><br/>
                                  <ReactQuill value={editorDescription} onChange={handleEditoroDescriptionChange} style={editorStyle} modules={modules} formats={formats} />
                                  {errors.post_description ? <><span className="text-danger">{errors.post_description}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Image*</h5><br/>
                                  <input type="file" onChange={handleImageUpload} class="form-control"/>
                                  {errors.post_img ? <><span className="text-danger">{errors.post_img}</span><br/></> : null}
                              </div>      
                              {!selectedPostId ? 
                                <div class="card-header">
                                  <div className="file-uploader" onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()} onDragLeave={(e) => e.preventDefault()}>
                                    <div className="uploader-header">
                                      <h2 className="uploader-title"></h2>
                                      <h4 className="file-completed-status">
                                        {completedFiles} / {totalFiles} files completed
                                      </h4>
                                    </div>
                                    <ul className="file-list">
                                      {files.map((file) => (
                                        <li key={file.uniqueIdentifier} className="file-item">
                                          <div className="file-extension">{file.file.name.split(".").pop()}</div>
                                          <div className="file-content-wrapper">
                                            <div className="file-content">
                                              <div className="file-details">
                                                <h5 className="file-name">{file.file.name}</h5>
                                                <div className="file-info">
                                                  <small className="file-size">
                                                    {file.loadedKB.toFixed(2)} KB / {file.totalKB.toFixed(2)} KB
                                                  </small>
                                                  <small className="file-divider">•</small>
                                                  <small className="file-status" style={{ color: file.color }}>
                                                    {file.status}
                                                  </small>
                                                </div>
                                              </div>
                                              <button
                                                className="cancel-button"
                                                onClick={() => cancelUpload(file.uniqueIdentifier)}
                                              >
                                                <i class="bx bx-x"></i>
                                              </button>
                                            </div>
                                            <div className="file-progress-bar">
                                              <div
                                                className="file-progress"
                                                style={{ width: `${file.progress}%` }}
                                              ></div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                    <div className="file-upload-box">
                                      <h2 className="box-title">
                                        <span className="file-instruction"></span>
                                        <span className="file-browse-button" onClick={() => document.querySelector(".file-browse-input").click()}>
                                          Choisir
                                        </span>
                                      </h2>
                                      <input
                                        className="file-browse-input"
                                        type="file"
                                        multiple
                                        hidden
                                        onChange={(e) => handleSelectedFiles(e.target.files)}
                                      />
                                    </div>
                                  </div>                                
                                </div>
                              : null }
                          </div>
                          <div class="d-grid">
                              <button type="submit" class="btn btn-primary" onClick={loadingsubmitbutton ? null : addUpdatePost}>
                                {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                              </button>
                          </div> 
                      </form>
                    </Modal.Body>
                  </Modal>  

                  <Modal show={showModalFilePost} onHide={handleModalCloseFiles}>
                    <Modal.Header>
                      <Modal.Title>
                        Ajouter
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form ref={formRef} encType="multipart/form-data">                       
                        <div class="card">
                            <div class="card-header">
                              <div className="file-uploader" onDrop={handleFileDrop} onDragOver={(e) => e.preventDefault()} onDragLeave={(e) => e.preventDefault()}>
                                <div className="uploader-header">
                                  <h2 className="uploader-title"></h2>
                                  <h4 className="file-completed-status">
                                    {completedFiles} / {totalFiles} files completed
                                  </h4>
                                </div>
                                <ul className="file-list">
                                  {files.map((file) => (
                                    <li key={file.uniqueIdentifier} className="file-item">
                                      <div className="file-extension">{file.file.name.split(".").pop()}</div>
                                      <div className="file-content-wrapper">
                                        <div className="file-content">
                                          <div className="file-details">
                                            <h5 className="file-name">{file.file.name}</h5>
                                            <div className="file-info">
                                              <small className="file-size">
                                                {file.loadedKB.toFixed(2)} KB / {file.totalKB.toFixed(2)} KB
                                              </small>
                                              <small className="file-divider">•</small>
                                              <small className="file-status" style={{ color: file.color }}>
                                                {file.status}
                                              </small>
                                            </div>
                                          </div>
                                          <button
                                            className="cancel-button"
                                            onClick={() => cancelUpload(file.uniqueIdentifier)}
                                          >
                                            <i class="bx bx-x"></i>
                                          </button>
                                        </div>
                                        <div className="file-progress-bar">
                                          <div
                                            className="file-progress"
                                            style={{ width: `${file.progress}%` }}
                                          ></div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                                <div className="file-upload-box">
                                  <h2 className="box-title">
                                    <span className="file-instruction"></span>
                                    <span className="file-browse-button" onClick={() => document.querySelector(".file-browse-input").click()}>
                                      Choisir
                                    </span>
                                  </h2>
                                  <input
                                    className="file-browse-input"
                                    type="file"
                                    multiple
                                    hidden
                                    onChange={(e) => handleSelectedFiles(e.target.files)}
                                  />
                                </div>
                              </div>                                
                            </div>
                        </div>
                        <div class="d-grid">
                            <button type="submit" class="btn btn-primary" onClick={loadingsubmitbutton ? null : addFiles}>
                                {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                            </button>
                        </div> 
                      </form> <br/>                     
                      <table class="table table-hover my-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th class="d-md-table-cell">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loadingskeletonbuttonfiles ? <>{SkeletonTable(3,3)}</>:
                            <>
                                {datapostfiles && datapostfiles.map((file, index) => {                                                                              
                                    const descendingIndex = datapostfiles.length - index;
                                    return (
                                        <>
                                            <tr>
                                                <td>{descendingIndex}</td>
                                                <td class="d-xl-table-cell">
                                                    <Link to={file.file_path} target="_blank">
                                                        <img src={file.file_path} style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                                                    </Link>
                                                </td>              
                                                <td class="d-md-table-cell">
                                                    <a onClick={() => handleDeleteFile(file.file_id)} className="text-danger" style={{marginLeft:"20px"}}>
                                                      Delete 
                                                    </a>                                                                                                                         
                                                </td>
                                            </tr>                                
                                        </>  
                                    );
                                })} 
                            </> 
                          }                       
                        </tbody>
                      </table>                      
                    </Modal.Body>
                  </Modal>                                                   
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
