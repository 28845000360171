import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";

export default function LawView() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const navigate = useNavigate();

  const [datalaw,setDataLaw] = useState({});

  const {law_id} = useParams();

  const {law_version} = useParams();

  const [loadingskeleton, setLoadingSkeleton] = useState(false);

  useEffect(() => {
    getLawById();
  }, []);

  const getLawById = async () => {
    setLoadingSkeleton(true);
    axiosClient.get(`/law/${law_id}`).then( ({data})=> {
        setDataLaw(data.data);
        setLoadingSkeleton(false);
    }).catch(err => {
      setLoadingSkeleton(false);
      navigate('/'); 
    });
  };

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            {loadingskeleton ?  <p className="text-center">
                <i class="fa fa-refresh fa-spin" style={{ fontSize:"30px" }}></i>
            </p> :
                <>
                    <h1 class="mb-3">{datalaw.law_title} ({law_version})</h1>
                    <div class="row">
                        <embed  src={law_version == "Vf" ? datalaw.law_pdf_french : datalaw.law_pdf_english} style={{ height: "77vh" }} />
                    </div>
                </>
            }
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

