import React, { useContext, useEffect, useState } from 'react'
import avatar from "../assets/img/user-128.png"
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../axios-client';
import { AuthContext } from '../context/AuthContext';

export default function Navbar({ changeStyle  }) {

  const userImg = localStorage.getItem('profile_user_img');

  const userName = localStorage.getItem('profile_user_name');

  const { currentUser, token } = useContext(AuthContext);

  const [currentuser, setCurrentUser] = useState({});

  const [unreadReservations, setUnreadReservations] = useState([]);

  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // await axiosClient.post('/logout');      
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    axiosClient.get(`/user/${currentUser.id}`).then( ({data})=> {
      let list = data.data;
      setCurrentUser(list);
      console.log(data);
    }).catch(err => {
      navigate('/'); 
    });
  };

  return (
    <nav class="navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle js-sidebar-toggle collapsed" onClick={changeStyle}>
      <i class="hamburger align-self-center"></i>
    </a>
    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <li class="nav-item dropdown">
          <a
            class="nav-icon dropdown-toggle d-inline-block"
            href="#"
            data-bs-toggle="dropdown"
          >
            {/* <i class="align-middle" data-feather="settings"></i> */}
            {/* <i class="fa fa-user align-middle"></i> */}
          </a>

          <a
            class="nav-link dropdown-toggle d-none d-sm-inline-block"
            href="#"
            data-bs-toggle="dropdown"
          >
            <img
              src={userImg == '' || userImg == null  ? avatar : userImg }
              class="avatar img-fluid rounded me-1"
              alt="Profile"
            />
            <span class="text-dark">{userName}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <Link class="dropdown-item" to="/profile">
              <i class="align-middle me-1" data-feather="user"></i>
              Profile
            </Link>
            <div class="dropdown-divider"></div>
            {currentUser.type == "admin" ?
            <Link class="dropdown-item" to="/users">
              <i class="align-middle me-1" data-feather="settings"></i>
              Users
            </Link> : null }
            <Link to={'/help'} class="dropdown-item">
              <i class="align-middle me-1" data-feather="help-circle"></i>
              Help Center
            </Link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" onClick={() => handleLogout()}>
              Log out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  )
}
