import React, { useContext, useEffect, useRef, useState } from 'react'
import "./assets/css/app.css"
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import axiosClient from './axios-client';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default function ForgetPassword() {

  const navigate = useNavigate();
  const inputs = useRef([]);
  const [validationerror , setValidationError] = useState("");
  const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);
  const [errorauthentification,setErrorAuthentification] = useState("");

  const formRef = useRef();

  const addInputs = el => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el)
    }
  }
  
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleForgetPassword = async (event) =>{
    event.preventDefault();
    const errors = {};

    if (inputs.current[0].value.trim() === '') {
      errors.email = 'Email is required';
    } else if (!isValidEmail(inputs.current[0].value)) {
      errors.email = 'Email address format is not valid';
    }

    if (Object.keys(errors).length === 0) {
      setLoadingSubmitButton(true);
      await axiosClient.get(`/forgetpassword/${inputs.current[0].value.trim()}`).then(({data})  => {
        Swal.fire({position: 'top-right',icon: 'success',title: 'Good!',text: 'Vous avez reçu un nouveau sur votre adresse mail',showConfirmButton: true,confirmButtonColor: '#10518E',})
        setValidationError(errors);
        setLoadingSubmitButton(false);
        navigate('/'); 
      }).catch(err => {
        const response = err.response;
        if (response && response.status === 422){
          if (response.data.errormessage) {
            errors.user = "Vous n'existez pas en tant qu'utilisateur";
            setErrorAuthentification(errors);
          }
        }
      });
      setLoadingSubmitButton(false);
    }else{
      setValidationError(errors);
    }
  }

  return (
    <main class="d-flex w-100">
    <div class="container d-flex flex-column">
        <div class="row vh-100">
            <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                <div class="d-table-cell align-middle">

                    <div class="text-center mt-4">
                        <h1 class="h2">Welcome back To Administrator Esquire!</h1>
                        <p class="lead">
                          Forget Password
                        </p>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="m-sm-3">
                                <form ref={formRef}>
                                    {errorauthentification.user ? <div className="alert alert-danger">
                                      { errorauthentification.user }
                                    </div> : null}
                                    <div class="mb-3">
                                        <label class="form-label">Email</label>
                                        <input class="form-control form-control-lg" ref={addInputs} type="email" placeholder="Enter your email" />
                                        {validationerror.email && <span className="text-danger">{validationerror.email}</span>}
                                    </div>
                                    <div class="d-grid gap-2 mt-3">
                                        <button onClick={handleForgetPassword} class="btn btn-lg btn-primary">
                                            {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mb-3">
                        did you sign in? <Link to='/'>Click here</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
  )
}

