import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../assets/css/file.css"

export default function Help() {
    const [style, setStyle] = useState("sidebar js-sidebar");

    const changeStyle = () => {
      if (style === "sidebar js-sidebar collapsed") {
        setStyle("sidebar js-sidebar");
      } else {
        setStyle("sidebar js-sidebar collapsed");
      }
    };
  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Help</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill">
                  <div class="card-header">
                    
                  </div>
                  <div class="container">
                    <div className="row">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate maiores tempora est quia odit fuga, nostrum ipsa alias similique inventore incidunt fugit veniam minima. Ut obcaecati nulla accusamus architecto tenetur.
                        </p>
                    </div>
                  </div>               
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
