import { Routes,Route, Navigate } from "react-router-dom";
import SignIn from "./SignIn";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";
import Dashboard from "./private/Dashboard";
import SignUp from "./private/SignUp";
import "./assets/css/app.css";
import "./assets/css/sketeleton.css";
import "./assets/css/customerfile.css";
import ForgetPassword from "./ForgetPassword";
import Profile from "./private/Profile";
import Post from "./private/Post";
import User from "./private/User";
import Codepromo from "./private/Codepromo";
import Laws from "./private/Laws";
import LawView from "./private/LawView";
import Subscription from "./private/Subscription";
import Help from "./private/Help";
import Categories from "./private/Category";

function App() {
  const { currentUser, token } = useContext(AuthContext);
  const RequireAuth = ({ children }) => {
    const isAuthenticated = currentUser && token;  
    return currentUser ? children : <Navigate to="/" />;
  };
  
  return (
    <>
      <Routes>

        <Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>} />

        <Route path="/profile" element={<RequireAuth><Profile/></RequireAuth>} />

        <Route path="/posts" element={<RequireAuth><Post/></RequireAuth>} />

        <Route path="/categories" element={<RequireAuth><Categories/></RequireAuth>} />

        <Route path="/codepromos" element={<RequireAuth><Codepromo/></RequireAuth>} />

        <Route path="/laws" element={<RequireAuth><Laws/></RequireAuth>} />
        <Route path="/law/:law_id/:law_version" element={<RequireAuth><LawView/></RequireAuth>} /> 

        <Route path="/users" element={<RequireAuth><User/></RequireAuth>} />

        <Route path="/help" element={<RequireAuth><Help/></RequireAuth>} />

        <Route path="/subscriptions" element={<RequireAuth><Subscription/></RequireAuth>} />

        <Route path="/sign-in" element={<SignIn/>} />
        <Route path="/sign-up" element={<RequireAuth><SignUp/></RequireAuth>} /> 
        <Route path="/forget-password" element={<ForgetPassword/>} />
        <Route path="/" element={<SignIn/>} />
      </Routes>
    </>
  );
}

export default App;
