import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axiosClient from "../axios-client";
import Swal from "sweetalert2";
export default function Dashboard() {

  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  }

  const [laws, setlaws] = useState([]);

  const [posts, setPosts] = useState([]);

  const [codepromos, setCodepromos] = useState([]);

  useEffect(() => {
    getlaws() && getPosts() && getCodepromos();
  }, []);

  const getlaws = async () => {
    axiosClient.get('/laws').then( ({data})=> {
      setlaws(data.data);
    }).catch(err => {
      console.log(err);
    });
  };
  
  const getPosts = async () => {
    axiosClient.get('/posts').then( ({data})=> {
      setPosts(data.data);
    }).catch(err => {
      console.log(err);
    });
  };


  const getCodepromos = async () => {
    axiosClient.get('/codepromos').then( ({data})=> {
      setCodepromos(data.data);
    }).catch(err => {
      console.log(err);
    });
  };
  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">
              <strong>Analytics</strong> Dashboard
            </h1>

            <div class="row">
              <div class="col-xl-12 col-xxl-12 d-flex">
                <div class="w-100">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              <h5 class="card-title">Amounts</h5>
                            </div>

                            <div class="col-auto">
                              <div class="stat text-primary">
                                <i class="align-middle" data-feather="truck"></i>
                              </div>
                            </div>
                          </div>
                          <h1 class="mt-1 mb-3">{laws.length}</h1>
                          <div class="mb-0">
                          </div>
                        </div>
                      </div>
                    </div>                     
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              <h5 class="card-title">Laws</h5>
                            </div>

                            <div class="col-auto">
                              <div class="stat text-primary">
                                <i class="align-middle" data-feather="truck"></i>
                              </div>
                            </div>
                          </div>
                          <h1 class="mt-1 mb-3">{laws.length}</h1>
                          <div class="mb-0">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              <h5 class="card-title">Posts</h5>
                            </div>

                            <div class="col-auto">
                              <div class="stat text-primary">
                                <i class="align-middle" data-feather="truck"></i>
                              </div>
                            </div>
                          </div>
                          <h1 class="mt-1 mb-3">{posts.length}</h1>
                          <div class="mb-0">
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div class="col-sm-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col mt-0">
                              <h5 class="card-title">Codepromos</h5>
                            </div>

                            <div class="col-auto">
                              <div class="stat text-primary">
                                <i class="align-middle" data-feather="truck"></i>
                              </div>
                            </div>
                          </div>
                          <h1 class="mt-1 mb-3">{codepromos.length}</h1>
                          <div class="mb-0">
                          </div>
                        </div>
                      </div>
                    </div>                                         
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer/>
      </div>
    </div>
  );
}
