import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import iconuser from '../assets/img/user-128.png'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import { AuthContext } from "../context/AuthContext";

export default function Subscription() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const { currentUser, token } = useContext(AuthContext);

  const [subscriptions, setSubscriptions] = useState([]);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  useEffect(() => {
    getSubscriptions();
  }, []);

  const getSubscriptions = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/subscriptions').then( ({data})=> {
      setSubscriptions(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Subscriptions</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th class="d-xl-table-cell">Image</th>
                        <th>Name</th>
                        <th>Amount</th>                        
                        <th>Delay</th>
                        <th>Net Amount</th>
                        <th>Codepromo</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(5,8)}</>:
                        <>
                            {subscriptions && subscriptions.map((subscription, index) => {                                                                                                         
                                const descendingIndex = subscriptions.length - index;
                                return (
                                    <>
                                      <tr>
                                          <td>{descendingIndex}</td>
                                          <td className="d-md-table-cell">
                                            <Link target="_blank" to={!subscription.user.user_img || subscription.user.user_img == "" || subscription.user.user_img == null ? iconuser : subscription.user.user_img}>
                                              <img src={!subscription.user.user_img || subscription.user.user_img == "" || subscription.user.user_img == null ? iconuser : subscription.user.user_img} style={{ height: "40px", width: "40px", borderRadius: "10px" }} /> 
                                            </Link>
                                          </td>     
                                          <td>{subscription.user.user_name}</td>    
                                          <td style={{ fontWeight:"bold" }}>
                                            {subscription.subscription_amount} XFA
                                          </td>                                                                              
                                          <td>{subscription.subscription_date_end}</td>
                                          <td className="text-primary" style={{ fontWeight:"bold" }}>
                                            {subscription.subscriptionAmountNet} XFA
                                          </td>
                                          <td>{subscription.subscription_codopromo}</td>
                                          <td>{subscription.subscription_created_at}</td>
                                      </tr>
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>                
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
