import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import avatar from "../assets/img/avatars/avatar.jpg"
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export default function Codepromo() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const formRef = useRef();

  const [selectedCodepromoId, setSelectedCodepromoId] = useState(null);

  const [codepromos, setCodepromos] = useState([]);

  const [datacodepromo,setDataCodepromo] = useState({});

  const inputcodepromos = useRef([]);

  const [showModal, setShowModal] = useState(false);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);

  const [errors,setErrors] = useState({});

  const [success,setSucces] = useState(""); 

  const addInputscodepromo = el => {
    if (el && !inputcodepromos.current.includes(el)) {
      inputcodepromos.current.push(el)
    }
  }

  const handleModalClose = () => {
    setShowModal(false);
    setErrors({});
    setSelectedCodepromoId(null);
    inputcodepromos.current = [];
  };

  const handleModalOpen = async (id) => {
    if (id != "") {
      setSelectedCodepromoId(id);
      await axiosClient.get(`/codepromos/${id}`).then(({data}) => {
        let list = data.data;
        setDataCodepromo(list);
      });
    }
    inputcodepromos.current = [];   
    setShowModal(true);
  }

  const addUpdateCodepromo = async(event) => {
    event.preventDefault();
    const errors = {};

    if (inputcodepromos.current[0].value.trim() == '') {
      errors.codepromo_name = 'The name field is required.';
    }        

    if (inputcodepromos.current[1].value.trim() == '') {
        errors.codepromo_pourcent = 'The pourcent field is required.';
    } 

    if (Object.keys(errors).length === 0) {
      setLoadingSubmitButton(true);
      if (!selectedCodepromoId) {
        const data = { name : inputcodepromos.current[0].value.trim(), pourcent : parseInt(inputcodepromos.current[1].value.trim()) }              
        await axiosClient.post('/codepromos',data).then(async ({data})  => {            
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Codepromo added success',showConfirmButton: true});
          setSucces("Codepromo added success !!");
          setErrors(errors);
          setTimeout(() => { setSucces('');}, 3000);
          formRef.current.reset();
          setLoadingSubmitButton(false);
          getCodepromos();
        }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
            }            
            if (response && response.status === 500) {
                Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
            }
            setLoadingSubmitButton(false);
        });
      }else{
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', inputcodepromos.current[0].value.trim());
        formData.append('pourcent', parseInt(inputcodepromos.current[1].value.trim()));          

        await axiosClient.post(`/codepromos/${selectedCodepromoId}`,formData).then(async ({data})  => {                      
          setSucces("Codepromo updated success !!");
          setErrors(errors);          
          setTimeout(() => { setSucces('');}, 3000);
          setLoadingSubmitButton(false);
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Codepromo updated success',showConfirmButton: true});
          getCodepromos();
        }).catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
          }            
          if (response && response.status === 500) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
          }
          setLoadingSubmitButton(false);
        });
      }
    } else{
      setErrors(errors);
      setLoadingSubmitButton(false);
    } 
  }

  useEffect(() => {
    getCodepromos();
  }, []);

  const getCodepromos = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/codepromos').then( ({data})=> {
      setCodepromos(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  const handleDelete = async(id) => {
    Swal.fire({
      title: 'Deletion', text: 'do you really want to delete this codepromo?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#10518E', cancelButtonColor: '#d33', confirmButtonText: 'confirm', cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('_method', 'DELETE');
        axiosClient.post(`/codepromos/${id}`,formData).then( () => {
          Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'Codepromo deleted successfully',showConfirmButton: true});
          getCodepromos()
        }).catch(err => {
          const response = err.response;
          if (response.data.message) {
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `${response.data.message}` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
          }
        });
      }
    });
  };

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Codepromos</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <h5 class="card-title mb-0">Latest Codepromos</h5>
                    <h5 class="card-title mb-0" style={{float:"right"}}>
                        <Link onClick={() => handleModalOpen("")} title="Ajouter" class="btn btn-primary" style={{ borderRadius : "2px" }}><i class="fa fa-plus"></i> </Link>
                    </h5>
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Pourcent</th>
                        <th class="d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(7,4)}</>:
                        <>
                            {codepromos && codepromos.map((codepromo, index) => {                                                                              
                                const descendingIndex = codepromos.length - index;
                                return (
                                    <>
                                        <tr>
                                            <td>{descendingIndex}</td>                                   
                                            <td>{codepromo.codepromo_name}</td>
                                            <td>{codepromo.codepromo_pourcent}%</td>
                                            <td class="d-md-table-cell">
                                                <a onClick={() => handleModalOpen(codepromo.codepromo_id)} className="text-primary">
                                                    Update
                                                </a>
                                                <a onClick={() => handleDelete(codepromo.codepromo_id)} className="text-danger" style={{marginLeft:"20px"}}>
                                                    Delete 
                                                </a>                                                                           
                                            </td>
                                        </tr>                                
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>
                  <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>
                        {selectedCodepromoId ? "Update Codepromo" : "Add Codepromo"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form ref={formRef} encType="multipart/form-data">
                          {success ? <div class="alert alert-success alert-dismissible fade show" role="alert">
                              { success }
                          </div>: null}  
                          {errors.error ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
                              { errors.error }
                          </div>: null}                    
                          <div class="card">
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Name*</h5><br/>
                                  <input type="text" ref={addInputscodepromo} class="form-control" defaultValue={selectedCodepromoId ? datacodepromo.codepromo_name : ""}/>
                                  {errors.codepromo_name ? <><span className="text-danger">{errors.codepromo_name}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Pourcent*</h5><br/>
                                  <input type="number" ref={addInputscodepromo} class="form-control" defaultValue={selectedCodepromoId ? datacodepromo.codepromo_pourcent : ""}/>
                                  {errors.codepromo_pourcent ? <><span className="text-danger">{errors.codepromo_pourcent}</span><br/></> : null}
                              </div>                       
                          </div>
                          <div class="d-grid">
                              <button type="submit" class="btn btn-primary" onClick={loadingsubmitbutton ? null : addUpdateCodepromo}>
                                  {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                              </button>
                          </div> 
                      </form>
                    </Modal.Body>
                  </Modal>                                   
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
