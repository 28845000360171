import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import avatar from "../assets/img/avatars/avatar.jpg"
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export default function Categories() {

  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image',
  ];

  const editorStyle = {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '20px',
  };

  const formRef = useRef();

  const [selectedImage, setSelectedImage] = useState(null);

  const [fileurl,setFileUrl] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [editorDescription, setEditorDescription] = useState('');

  const [categories, setcategories] = useState([]);

  const [datacategory,setDataCategory] = useState({});

  const inputcategories = useRef([]);

  const [showModal, setShowModal] = useState(false);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);

  const [errors,setErrors] = useState({});

  const [success,setSucces] = useState(""); 

  const addInputsLaw = el => {
    if (el && !inputcategories.current.includes(el)) {
      inputcategories.current.push(el)
    }
  }

  const handleModalClose = () => {
    setShowModal(false);
    setErrors({});
    setSelectedCategoryId(null);
    inputcategories.current = [];
    setEditorDescription("");
    setSelectedImage(null);
  };

  const handleEditoroDescriptionChange = (html) => {
    setEditorDescription(html);
  };

  const handleModalOpen = async (id) => {
    if (id != "") {
      setSelectedCategoryId(id);
      await axiosClient.get(`/category/${id}`).then(({data}) => {
        let list = data.data;
        setDataCategory(list);
        setEditorDescription(list.category_description);
      });
    }
    inputcategories.current = [];   
    setShowModal(true);
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
      setFileUrl(URL.createObjectURL(file));
    } else {
      Swal.fire({position: 'Center',icon: 'warning',title: 'Oops!',text: 'Veuillez sélectionner un fichier image valide.',showConfirmButton: true});
    }
  };

  const addUpdateCategory = async(event) => {
    event.preventDefault();
    const errors = {};

    if (inputcategories.current[0].value.trim() == '') {
      errors.category_title = 'The name field is required.';
    } 
    
    if (!selectedCategoryId) {
      if (!fileurl) {
        errors.category_img = 'This image is required.';
      }              
    } 

    if (Object.keys(errors).length === 0) {
      setLoadingSubmitButton(true);
      if (!selectedCategoryId) {
        const lawData = new FormData();
        lawData.append('title', inputcategories.current[0].value.trim());
        lawData.append('description', editorDescription);
        lawData.append('img', selectedImage);
        await axiosClient.post('/categories',lawData,{headers: {'Content-Type': 'multipart/form-data',},}).then(async ({data})  => {
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Category added success',showConfirmButton: true});
          setSucces("Category added success !!");
          setErrors(errors);
          setTimeout(() => { setSucces('');}, 3000);
          formRef.current.reset();
          setSelectedImage([]);
          setFileUrl(null);
          setEditorDescription("");
          setLoadingSubmitButton(false);
          getcategories();
        }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
            }            
            if (response && response.status === 500) {
                Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
            }
            setLoadingSubmitButton(false);
        });
      }else{
        const lawData = new FormData();
        lawData.append('_method', 'PUT');
        lawData.append('title', inputcategories.current[0].value.trim());
        lawData.append('description', editorDescription);
        if (selectedImage) {
          lawData.append('img', selectedImage);          
        }       

        await axiosClient.post(`/category/${selectedCategoryId}`,lawData,{headers: {'Content-Type': 'multipart/form-data',},}).then(async ({data})  => {          
          setSucces("Category updated success !!");
          setErrors(errors);          
          setTimeout(() => { setSucces('');}, 3000);
          setLoadingSubmitButton(false);
          Swal.fire({position: 'Center',icon: 'success',title: 'Success',text: 'Category updated success',showConfirmButton: true});
          getcategories();
        }).catch(err => {
          const response = err.response;
          if (response && response.status === 422) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred required fields',showConfirmButton: true});
          }            
          if (response && response.status === 500) {
            Swal.fire({position: 'Center',icon: 'error',title: 'Oops!',text: 'An error occurred while processing your request',showConfirmButton: true});
          }
          setLoadingSubmitButton(false);
        });
      }
    } else{
      setErrors(errors);
      setLoadingSubmitButton(false);
    } 
  }

  useEffect(() => {
    getcategories();
  }, []);

  const getcategories = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/categories').then( ({data})=> {
      setcategories(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  const handleDelete = async(id) => {
    Swal.fire({
      title: 'Deletion', text: 'do you really want to delete this category?', icon: 'warning', showCancelButton: true, confirmButtonColor: '#10518E', cancelButtonColor: '#d33', confirmButtonText: 'confirm', cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('_method', 'DELETE');
        axiosClient.post(`/category/${id}`,formData).then( () => {
          Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'Law deleted successfully',showConfirmButton: true});
          getcategories()
        }).catch(err => {
          const response = err.response;
          if (response.data.message) {
            Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: `${response.data.message}` ,showConfirmButton: true,confirmButtonColor: '#10518E',})
          }
        });
      }
    });
  };

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Categories</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <h5 class="card-title mb-0">Latest Categories</h5>
                    <h5 class="card-title mb-0" style={{float:"right"}}>
                        <Link onClick={() => handleModalOpen("")} title="Ajouter" class="btn btn-primary" style={{ borderRadius : "2px" }}><i class="fa fa-plus"></i> </Link>
                    </h5>
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th class="d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(7,6)}</>:
                        <>
                            {categories && categories.map((category, index) => {                                                                              
                                const descendingIndex = categories.length - index;

                                let categoryDescription = category.category_description;
                                if (categoryDescription.length > 15 ) {
                                    categoryDescription = categoryDescription.substr(0, 15)+"..";
                                }
                                return (
                                    <>
                                        <tr>
                                            <td>{descendingIndex}</td>
                                            <td class="d-xl-table-cell">
                                                <Link to={category.category_img} target="_blank">
                                                    <img src={category.category_img} style={{ height: "40px", width: "40px", borderRadius: "5px", objectFit: "cover" }} />
                                                </Link>
                                            </td>                                              
                                            <td>{category.category_title}</td>
                                            <td title={category.category_description.replace(/<[^>]+>/g, '')} dangerouslySetInnerHTML={{ __html: categoryDescription }}></td>
                                            <td class="d-md-table-cell">
                                                <a onClick={() => handleModalOpen(category.category_id)} className="text-primary">
                                                    Update
                                                </a>
                                                <a onClick={() => handleDelete(category.category_id)} className="text-danger" style={{marginLeft:"20px"}}>
                                                    Delete 
                                                </a>                                                                           
                                            </td>
                                        </tr>                                
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>
                  <Modal show={showModal} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>
                        {selectedCategoryId ? "Update Category" : "Add Category"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form ref={formRef} encType="multipart/form-data">
                          {success ? <div class="alert alert-success alert-dismissible fade show" role="alert">
                              { success }
                          </div>: null}  
                          {errors.error ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
                              { errors.error }
                          </div>: null}
                          {errors.error_img ? <div class="alert alert-danger alert-dismissible fade show" role="alert">
                              { errors.error_img }
                          </div>: null}                        
                          <div class="card">
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Title*</h5><br/>
                                  <input type="text" ref={addInputsLaw} class="form-control" defaultValue={selectedCategoryId ? datacategory.category_title : ""}/>
                                  {errors.category_title ? <><span className="text-danger">{errors.category_title}</span><br/></> : null}
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Description</h5><br/>
                                  <ReactQuill value={editorDescription} onChange={handleEditoroDescriptionChange} style={editorStyle} modules={modules} formats={formats} />
                              </div>
                              <div class="card-header">
                                  <h5 class="card-title mb-0">Image*</h5><br/>
                                  <input type="file" onChange={handleImageUpload} class="form-control"/>
                                  {errors.category_img ? <><span className="text-danger">{errors.category_img}</span><br/></> : null}
                              </div>                                                                               
                          </div>
                          <div class="d-grid">
                              <button type="submit" class="btn btn-primary" onClick={loadingsubmitbutton ? null : addUpdateCategory}>
                                {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Submit
                              </button>
                          </div> 
                      </form>
                    </Modal.Body>
                  </Modal>                                   
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}