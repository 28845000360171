import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import iconuser from '../assets/img/user-128.png'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/file.css"
import axiosClient from "../axios-client";
import SkeletonTable from "../components/SkeletonTable";
import { AuthContext } from "../context/AuthContext";

export default function User() {
  const [style, setStyle] = useState("sidebar js-sidebar");

  const changeStyle = () => {
    if (style === "sidebar js-sidebar collapsed") {
      setStyle("sidebar js-sidebar");
    } else {
      setStyle("sidebar js-sidebar collapsed");
    }
  };

  const { currentUser, token } = useContext(AuthContext);

  const [users, setUsers] = useState([]);

  const [loadingskeletonbutton, setLoadingSkeletonButton] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoadingSkeletonButton(true);
    axiosClient.get('/users').then( ({data})=> {
      setUsers(data.data);
      setLoadingSkeletonButton(false);
    }).catch(err => {
      setLoadingSkeletonButton(false);
    });
  };

  const ChangeStateUser  = async(id,user_state) => {
    Swal.fire({
      title: 'Choose an Operation', icon: 'question',showDenyButton: true,showCancelButton: true, confirmButtonColor: '#10518E',confirmButtonText: 'Activer',denyButtonText: `Desactiver`, cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(user_state !== "asset")
        {
          let state = "asset";
          const formData = new FormData();
          formData.append('_method', 'PUT');
          await axiosClient.post(`/user/edit-state/${id}/${state}`,formData).then(async ({data})  => {
            getUsers();
            Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'This User has been activated',showConfirmButton: true})
          }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
              Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: 'An error occurred while executing the program',showConfirmButton: true,confirmButtonColor: '#10518E',})
            }
          });
        }
        else{
          Swal.fire({position: 'top-right',icon: 'warning',title: 'Information',text: 'This User is already active',showConfirmButton: true,confirmButtonColor: '#10518E',})
        }
      }
      else if (result.isDenied) {
        if(user_state !== "idle")
        {
          let state = "idle";
          const formData = new FormData();
          formData.append('_method', 'PUT');
          await axiosClient.post(`/user/edit-state/${id}/${state}`,formData).then(async ({data})  => {
            getUsers();
            Swal.fire({position: 'top-right',icon: 'success',title: 'Thanks you!',text: 'This User has been deactivated',showConfirmButton: true})
          }).catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
              Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: 'An error occurred while executing the program',showConfirmButton: true,confirmButtonColor: '#10518E',})
            }
          });
        }
        else{
          Swal.fire({position: 'top-right',icon: 'warning',title: 'Information',text: 'This User is already inactive',showConfirmButton: true,confirmButtonColor: '#10518E',})
        }
      }
    });
  };  

  return (
    <div class="wrapper">
      <Sidebar style={style} />
      <div class="main">
        <Navbar changeStyle={changeStyle} />
        <main class="content">
          <div class="container-fluid p-0">
            <h1 class="h3 mb-3">Users</h1>
            <div class="row">
              <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                <div class="card flex-fill" style={{ overflowY : "scroll", scrollBehavior: "inherit", height : "74vh" }}>
                  <div class="card-header">
                    <h5 class="card-title mb-0" style={{float:"right"}}>
                        <Link to="/sign-up" title="Ajouter" class="btn btn-primary" style={{ borderRadius : "2px" }}><i class="fa fa-plus"></i> </Link>
                    </h5>
                  </div>
                  <table class="table table-hover my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th class="d-xl-table-cell">Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>State</th>
                        <th class="d-md-table-cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingskeletonbutton ? <>{SkeletonTable(5,6)}</>:
                        <>
                            {users && users.map((user, index) => { 
                                let classState = "";
                                let contentState = "";
                                if (user.user_state === "asset") {classState = "bg-primary";contentState = "Asset";} 
                                else{ classState = "bg-danger";contentState = "Idle";}                                                                                                             
                                const descendingIndex = users.length - index;
                                return (
                                    <>
                                        { user.user_id != currentUser.id ?
                                            <tr>
                                                <td>{descendingIndex}</td>
                                                <td className="d-md-table-cell">
                                                    <Link to={!user.user_img || user.user_img == "" || user.user_img == null ? iconuser : user.user_img}>
                                                        <img src={!user.user_img || user.user_img == "" || user.user_img == null ? iconuser : user.user_img} style={{ height: "40px", width: "40px", borderRadius: "10px" }} /> 
                                                    </Link>
                                                </td>                                             
                                                <td>{user.user_name}</td>
                                                <td>{user.user_email}</td>
                                                <td><span class={`badge ${classState}`}>{contentState}</span></td>
                                                <td>
                                                    <a onClick={() => ChangeStateUser(user.user_id,user.user_state)} className="text-warning">
                                                        Act / Des 
                                                    </a>                            
                                                </td>
                                            </tr>
                                        : null }
                                    </>  
                                );
                            })} 
                        </> 
                      }                       
                    </tbody>
                  </table>                
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

