import React from "react";

export default function Footer() {
  return (
    <footer class="footer">
      <div class="container-fluid">
        <div class="row text-muted">
          <div class="col-6 text-start">
            <p class="mb-0">
              <a class="text-muted">
                <strong>Esquire</strong>
              </a>{" "}
              - Den's Empire Technology
              &copy;
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
