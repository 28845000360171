import React, { useContext, useEffect, useRef, useState } from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import avatar from "../assets/img/avatars/avatar-4.jpg"
import { AuthContext } from '../context/AuthContext';
import axiosClient from '../axios-client';
import Swal from 'sweetalert2';
import iconuser from '../assets/img/user-128.png'

export default function Profile() {
    const [style, setStyle] = useState("sidebar js-sidebar");
    
    const changeStyle = () => {
        if (style === "sidebar js-sidebar collapsed") {
            setStyle("sidebar js-sidebar");
        } else {
            setStyle("sidebar js-sidebar collapsed");
        }
    }
    
    const { currentUser, token } = useContext(AuthContext);

    const formRef = useRef();

    const [currentuser,setCurrentUser] = useState({});

    const [errors,setErrors] = useState({});

    const [success,setSuccess] = useState("");

    const [selectedImage, setSelectedImage] = useState(null);

    const [fileurl,setFileUrl] = useState(null);

    const inputusers = useRef([]);

    const [loadingsubmituser, setLoadingSubmitUser] = useState(false);

    const addInputsUser = el => {
        if (el && !inputusers.current.includes(el)) {
          inputusers.current.push(el)
        }
    }

    useEffect(() => {
        const getUserbyId = async () => {
          axiosClient.get(`/user/${currentUser.id}`).then( ({data})=> {
            setCurrentUser(data.data);
          }).catch(err => {
            console.log(err);
          });
        };
        currentUser.id && getUserbyId();
    }, [currentUser.id]);    

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedImage(file);
            setFileUrl(URL.createObjectURL(file));
        } else {
            Swal.fire({position: 'Center',icon: 'warning',title: 'Oops!',text: 'Veuillez sélectionner un fichier image valide.',showConfirmButton: true});
        }
    };

    const handleImageRemove = () => {
        setSelectedImage(null);
        const inputElement = document.getElementById('uploadImage');
        if (inputElement) {
          inputElement.value = '';
        }
    };

    const isValidEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const updateUser = async(event) => {
        event.preventDefault();
        const errors = {};
    
        if (inputusers.current[0].value.trim() === '') {
          errors.user_name = 'Name is required';
        }
        
        if (inputusers.current[1].value.trim() === '') {
          errors.user_email = 'Email is required';
        } else if (!isValidEmail(inputusers.current[1].value)) {
          errors.user_email = 'Email address format is not valid';
        }  
        
        if (inputusers.current[2].value.trim() === '') {
          errors.user_phone = 'Phone number is required';
        }        
    
        if (Object.keys(errors).length === 0) {
          if (!fileurl) {
            setLoadingSubmitUser(true);
            const formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('name', inputusers.current[0].value.trim());
            formData.append('email', inputusers.current[1].value.trim());
            formData.append('phone', inputusers.current[2].value.trim());
    
            if (inputusers.current[3].value.trim() != '') {
              formData.append('password', inputusers.current[3].value.trim());
            }
    
            await axiosClient.post(`/user/edit/${currentUser.id}`,formData).then(({data})  => {
              localStorage.setItem('profile_user_name', inputusers.current[0].value.trim());              
              setSuccess("Informations Updated success !!");
              setLoadingSubmitUser(false);
              setTimeout(() => { setSuccess('');}, 3000);
              Swal.fire({position: 'Center',icon: 'success',title: 'Thanks you!',text: 'Informations Updated success',showConfirmButton: true});
              setErrors(errors);
            }).catch(err => {
              const response = err.response;
              if (response && response.status === 422) {
                Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: 'An error occurred while executing the program',showConfirmButton: true,confirmButtonColor: '#10518E',})
              }
            })
            setLoadingSubmitUser(false);
          } else {
            const formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('img', selectedImage);
            formData.append('name', inputusers.current[0].value.trim());
            formData.append('email', inputusers.current[1].value.trim());
            formData.append('phone', inputusers.current[2].value.trim());
            if (inputusers.current[3].value.trim() != '') {
              formData.append('password', inputusers.current[3].value.trim());
            }
            await axiosClient.post(`/user/edit/${currentUser.id}`,formData,{headers: {'Content-Type': 'multipart/form-data',},}).then(({data})  => {
              const { user, token, resourceuser } = data;
              localStorage.setItem('profile_user_img', resourceuser.user_img);
              localStorage.setItem('profile_user_name', inputusers.current[0].value.trim());              
              setSuccess("Informations Updated success !!");
              setLoadingSubmitUser(false);
              setTimeout(() => { setSuccess('');}, 3000);
              Swal.fire({position: 'Center',icon: 'success',title: 'Thanks you!',text: 'Informations Updated success',showConfirmButton: true});
              setErrors(errors);
            }).catch(err => {
              const response = err.response;
              if (response && response.status === 422) {
                Swal.fire({position: 'top-right',icon: 'error',title: 'Oops!',text: 'An error occurred while executing the program',showConfirmButton: true,confirmButtonColor: '#10518E',})
              }
            })
            setLoadingSubmitUser(false);
          }
        } else{
          setErrors(errors);
          setLoadingSubmitUser(false);
        }   
    }

    return (
        <div class="wrapper">
        <Sidebar style={style} />
        <div class="main">
            <Navbar changeStyle={changeStyle} />
            <main class="content">
            <div class="container-fluid p-0">
                <div class="mb-3">
                <h1 class="h3 d-inline align-middle">Profile</h1>
                </div>
                <div class="row">
                <div class="col-md-4 col-xl-3">
                    <div class="card mb-3">
                    <div class="card-header">
                        <h5 class="card-title mb-0">Profile Details</h5>
                    </div>
                    <div class="card-body text-center">
                        {selectedImage ? (
                            <img src={URL.createObjectURL(selectedImage)} class="img-fluid rounded-circle mb-2" style={{width : "128px", height :  "128px",objectFit:"cover" }} />
                        ) : (
                            <img src={currentuser.user_img == '' || currentuser.user_img == null  ? iconuser : currentuser.user_img} class="img-fluid rounded-circle mb-2" style={{width : "128px", height :  "128px",objectFit:"cover" }} />
                        )}
                        <div className="pt-2">
                            <label htmlFor="uploadImage" className="btn btn-primary btn-sm" title="Upload new profile image">
                                <i className="fa fa-upload" style={{ color : "white" }}></i>
                                <input type="file" id="uploadImage" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} />
                            </label>
                        </div>
                            {selectedImage && (
                            <button className="btn btn-danger btn-sm" style={{ marginTop : "-8px" }} onClick={handleImageRemove} title="Remove my profile image">
                                <i className="fa fa-trash"></i>
                            </button>
                        )}
                        <br/>
                        <h5 class="card-title mb-0">{currentuser.user_name}</h5>
                        <div class="text-muted mb-2">{currentuser.user_email}</div><br/>
                        {/* <div class="text-muted mb-2">{currentuser.user_type}</div> */}
                    </div>
                    </div>
                </div>

                <div class="col-md-8 col-xl-9">
                    <div class="card">
                    <div class="card-header">
                        <h5 class="card-title mb-0">Modifier vos informations</h5>
                    </div>
                    <div class="card-body h-100">
                        <div class="d-flex align-items-start">
                            <div class="flex-grow-1">
                                <form ref={formRef} encType="multipart/form-data">
                                    <div class="card">
                                      <div class="card-body">
                                        <h5 class="card-title mb-0">Name *</h5><br/>
                                        <input type="text" ref={addInputsUser} defaultValue={currentuser.user_name} class="form-control"/>
                                        {errors.user_name ? <span className="text-danger">{errors.user_name}</span> : null}
                                      </div>
                                    </div>
                                    <div class="card">
                                      <div class="card-body">
                                          <h5 class="card-title mb-0">Email *</h5><br/>
                                          <input type="text" ref={addInputsUser} defaultValue={currentuser.user_email} class="form-control"/>
                                          {errors.user_email ? <span className="text-danger">{errors.user_email}</span> : null}
                                      </div>
                                    </div>   
                                    <div class="card">
                                      <div class="card-body">
                                          <h5 class="card-title mb-0">Phone *</h5><br/>
                                          <input type="text" ref={addInputsUser} defaultValue={currentuser.user_phone} class="form-control"/>
                                          {errors.user_phone ? <span className="text-danger">{errors.user_phone}</span> : null}
                                      </div>
                                    </div>                                                                       
                                    <div class="card">
                                        <div class="card-header">
                                            <h5 class="card-title mb-0">Change Password</h5>
                                        </div>
                                        <div class="card-body">
                                            <input type="text" ref={addInputsUser} class="form-control"/>
                                        </div>
                                    </div> 
                                    <div class="d-grid">
                                        <button class="btn btn-primary" type="submit" onClick={loadingsubmituser ? null : updateUser}>
                                            {loadingsubmituser ? <i class="fa fa-refresh fa-spin"></i> : null}Update
                                        </button>
                                    </div> 
                                </form>                                                              
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </main>
            <Footer/>
        </div>
        </div>
    )
}
